import React, {useEffect, useState} from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Top from '../components/Top'
import Main from '../components/Main'

const IndexPage = () => {

  const [light, setLight] = useState(false)

  const handleScroll = () => {
      if (window.innerHeight / 2 < window.scrollY) {
        setLight(true)
        document.body.classList.add('light')
      } else {
        setLight(false)
        document.body.classList.remove('light')
      }
  }

  const setVh = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  useEffect(() => {
    console.log('8080')
    handleScroll()
    setVh()
    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', setVh)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', setVh)
    }
  }, [])

  return (
    <Layout>
      <SEO  />
      <Top light={light} />
      <Main light={light} />
    </Layout>
  )
}

export default IndexPage
