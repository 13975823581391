import React from 'react'
import styled from 'styled-components'

import Boxes from './Boxes'
import Contact from './Contact'

const Wrapper = styled.div`
  min-height: 100vh;
  padding: 10px 0 250px;
  position: relative;
  
  @media only screen and (min-width: 1250px) {
    padding: 0 0 130px;
  }
`

const Main = ({ light }) => {
  return (
    <Wrapper id="main">
      <Boxes light={light}></Boxes>
      <Contact></Contact>
      <div id={'contact'}></div>
    </Wrapper>
  )
}

export default Main
