import React from 'react'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'

const Wrapper = styled.div`
  @media only screen and (min-width: 1250px) {
    flex-grow: ${({ toLeft }) => (toLeft ? 0 : 1)};
    text-align: center;
  }
`

const Title = styled.h3`
  font-size: 36px;
  line-height: 41px;
  padding: 12px 30px;
  border-bottom: 1px solid
    ${({ theme, light }) => (light ? theme.black : theme.white)};
  flex-shrink: 0;
  cursor: pointer;
  font-weight: normal;
  position: relative;
  overflow: hidden;
  text-transform: uppercase;

  @media only screen and (max-width: 390px) {
    font-size: 30px;
    line-height: 36px;
    padding: 10px 15px;
  }

  @media only screen and (min-width: 1250px) {
    font-size: 40px;
    line-height: 44px;
    margin: 0 auto;
    padding: 20px 40px;
    white-space: nowrap;
    border-bottom: 0;
    border-right: ${({ last, theme, light }) =>
      last ? 0 : `1px solid ${light ? theme.black : theme.white}`};

    span {
      opacity: ${({ active }) => (active ? 0 : 1)};
    }

    &:after {
      content: '${({ text }) => text}';
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: ${({ theme, light }) =>
        light ? theme.black : theme.black};
      color: ${({ theme, light }) => (light ? theme.white : theme.white)};
      mix-blend-mode: difference;
      font-family: ${({ theme }) => theme.fontItalic};
      font-size: inherit;
      line-height: inherit;
      height: 100%;
      padding: inherit;
      transition: background-color ${({ theme }) => theme.inverseTrans};
      opacity: ${({ active }) => (active ? 1 : 0)};
    }
  }

  @media only screen and (min-width: 1400px) {
    font-size: 56px;
    line-height: 60px;
  }

  @media (hover: hover) and (min-width: 1250px) {
    &:hover {
      &:after {
        opacity: 1;
      }
      span {
        opacity: 0;
      }
    }

    &::before {
      --size: 0;

      content: '';
      position: absolute;
      left: var(--x);
      top: var(--y);
      width: var(--size);
      height: var(--size);
      background: radial-gradient(circle closest-side, black, transparent);
      transform: translate(-50%, -50%);
      transition: width 0.1s ease, height 0.1s ease;
      z-index: 0;
    }

    &:hover::before {
      --size: 600px;
    }
  }
`

const DescriptionWrapper = styled(({ light, ...rest }) => (
  <animated.div {...rest} />
))`
  overflow: hidden;
  border-bottom: 1px solid
    ${({ theme, light }) => (light ? theme.black : theme.white)};

  @media only screen and (min-width: 1250px) {
    display: none;
  }
`

const Description = styled.div`
  font-size: 16px;
  line-height: 20px;
  padding: 15px 30px;
  display: flex;
  align-items: flex-start;
  font-family: ${({ theme }) => theme.fontItalic};

  @media only screen and (max-width: 390px) {
    font-size: 14px;
    line-height: 16px;
    padding: 10px 15px;
  }

  @media only screen and (min-width: 1250px) {
    font-size: 27px;
    line-height: 31px;
    padding: 25px 40px;
  }

  @media only screen and (min-width: 1400px) {
    font-size: 37px;
    line-height: 41px;
  }
`

const Close = styled.button`
  padding-top: 3px;
  padding-right: 15px;

  svg {
    height: 14px;
    width: 14px;

    line {
      stroke: ${({ theme, light }) => (light ? theme.black : theme.white)};
    }
  }
`

const Box = ({ light, item, open, update, grow, last, toLeft, active }) => {
  const [descriptionRef, { height }] = useMeasure({ polyfill: ResizeObserver })

  const springProps = useSpring({
    height: open ? height : 0,
    opacity: open ? 1 : 0,
  })

  const handleMouseMove = e => {
    const x = e.clientX - e.target.offsetLeft
    const y = e.clientY - e.target.getBoundingClientRect().top

    e.target.style.setProperty('--x', `${x}px`)
    e.target.style.setProperty('--y', `${y}px`)
  }

  return (
    <Wrapper grow={grow} toLeft={toLeft}>
      <Title
        first={item.index === 0}
        light={light}
        onClick={() => update(item.index, !open)}
        last={last && !toLeft}
        active={active || open}
        text={item.title}
        onMouseMove={handleMouseMove}
      >
        <span>{item.title}</span>
      </Title>
      <DescriptionWrapper light={light} style={{ ...springProps }}>
        <Description ref={descriptionRef} light={light}>
          <Close
            light={light}
            onClick={() => update(item.index, false)}
            title="Close"
          >
            <svg
              width="15"
              height="16"
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="0.357235"
                y1="1.03103"
                x2="13.6913"
                y2="14.6472"
                stroke="black"
              />
              <line
                x1="13.6845"
                y1="1.61576"
                x2="0.469974"
                y2="14.8303"
                stroke="black"
              />
            </svg>
          </Close>
          <p>{item.description}</p>
        </Description>
      </DescriptionWrapper>
    </Wrapper>
  )
}

export default Box
