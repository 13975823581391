import React, { useState, useEffect } from 'react'

import Row from './Row'

const Boxes = ({ light }) => {
  const [openElements, setOpenElements] = useState([
    true,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ])
  const [openRow1Index, setOpenRow1Index] = useState(0)
  const [openRow2Index, setOpenRow2Index] = useState(false)
  const [openRow3Index, setOpenRow3Index] = useState(false)
  const [openRow4Index, setOpenRow4Index] = useState(false)

  const updateOpen = (index, value) => {
    let states = []

    openElements.forEach((element, i) => {
      states.push(value ? i === index : i === index ? null : false)
    })

    setOpenElements(states)
  }

  useEffect(() => {
    const arr1 = []
    const arr2 = []
    const arr3 = []
    const arr4 = []

    openElements.forEach((item, i) => {
      if (i === 0 || i === 1 || i === 2) {
        arr1.push(item)
        item && setOpenRow1Index( i )
        item === null && setOpenRow1Index( false )
      } else if (i === 3 || i === 4 ) {
        arr2.push(item)
        item && setOpenRow2Index( i - 3 )
        item === null && setOpenRow2Index( false )
      } else if (i === 5 || i === 6 ) {
        arr2.push(item)
        item && setOpenRow3Index( i - 5 )
        item === null && setOpenRow3Index( false )
      } else {
        arr4.push(item)
        item && setOpenRow4Index( i - 7 )
        item === null && setOpenRow4Index( false )
      }
    })

  }, [openElements])

  return (
    <div>
      <Row
        first
        light={light}
        update={updateOpen}
        open={openElements}
        openRowIndex={openRow1Index}
        els={[
          {
            title: 'VFX / CGI',
            description: 'Special effects for moving image & stills',
            index: 0,
          },
          {
            title: 'MOTION DESIGN',
            description: 'Creative animation & video post production',
            index: 1,
          },
          {
            title: '3D RENDERS',
            description: 'High definition product renders for campaigns',
            index: 2,
          },
        ]}
      ></Row>
      <Row
        update={updateOpen}
        open={openElements}
        light={light}
        openRowIndex={openRow2Index}
        els={[
          {
            title: 'DIGITAL APPAREL',
            description:
              'Digital fabrics and 3D garments, simulating the physics of real clothing in animation',
            index: 3,
          },
          {
            title: 'DIGITAL HUMAN',
            description:
              'Realistic human body simulations, emulating lifelike faces, hair, eyes, skins and mimics',
            index: 4,
          },
        ]}
      ></Row>
      <Row
        update={updateOpen}
        open={openElements}
        light={light}
        openRowIndex={openRow3Index}
        els={[
          {
            title: 'Artificial Intelligence',
            description:
              'Training and controlling neural networks for each project',
            index: 5,
          },
          {
            title: 'Art Direction',
            description:
              'Curating creative solutions for digital entertainment',
            index: 6,
          },
         
        ]}
      ></Row>
      <Row
        update={updateOpen}
        open={openElements}
        light={light}
        openRowIndex={openRow4Index}
        els={[
          {
            title: 'LIVE VISUALS',
            description:
              'Live emitted frequencies translated to on-screen stories',
            index: 7,
          },
          {
            title: 'SOUND DESIGN',
            description:
              'Image-enhancing sonic environments, synergetic audio-visual content',
            index: 8,
          },
        ]}
      ></Row>
    </div>
  )
}

export default Boxes
