import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import Box from './Box'
import RowDescription from './RowDescription'

const Wrapper = styled.div``

const Inner = styled.div`
  @media only screen and (min-width: 1250px) {
    display: flex;
    border-bottom: 1px solid
      ${({ theme, light }) => (light ? theme.black : theme.white)};
    border-top: ${({ theme, first, light }) =>
      first ? `1px solid ${light ? theme.black : theme.white}` : 0};
  }
`

const Row = ({ els, light, open, update, first, openRowIndex, toLeft }) => {
  const [descText, setDescText] = useState(els[openRowIndex] && els[openRowIndex].description)

  useEffect(() => {
    if (typeof openRowIndex === 'number') {
        setDescText(els[openRowIndex] && els[openRowIndex].description)
    }
  }, [openRowIndex])

  return (
    <Wrapper light={light}>
      <Inner first={first} light={light}>
        {els.map((item, i) => {
          return (
            <Box
              key={item.title}
              light={light}
              item={item}
              open={open[item.index]}
              update={update}
              grow={i === 1}
              last={i + 1 === els.length}
              toLeft={toLeft}
              active={openRowIndex === i}
            />
          )
        })}
      </Inner>
      <RowDescription
        open={openRowIndex !== false}
        text={descText}
        light={light}
        updateRow={() => update(open.indexOf(true), false)}
      />
    </Wrapper>
  )
}

export default Row
