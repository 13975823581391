import React from 'react'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'

const DescriptionWrapper = styled(({ light, ...rest }) => (
  <animated.div {...rest} />
))`
  display: none;
  overflow: hidden;
  border-bottom: 1px solid
    ${({ theme, light }) => (light ? theme.black : theme.white)};

  @media only screen and (min-width: 1250px) {
    display: block;
  }
`

const Description = styled.div`
  font-size: 16px;
  line-height: 20px;
  padding: 15px 30px;
  display: flex;
  align-items: flex-start;
  font-family: ${({ theme }) => theme.fontItalic};

  @media only screen and (max-width: 390px) {
    font-size: 14px;
    line-height: 16px;
    padding: 10px 15px;
  }

  @media only screen and (min-width: 1250px) {
    font-size: 27px;
    line-height: 31px;
    padding: 25px 40px;
  }

  @media only screen and (min-width: 1400px) {
    font-size: 37px;
    line-height: 41px;
  }
`

const Close = styled.button`
  padding-top: 2px;
  padding-right: 20px;

  svg {
    height: 25px;
    width: 25px;

    @media only screen and (min-width: 1400px) {
      height: 35px;
      width: 35px;
    }

    line {
      stroke: ${({ theme, light }) => (light ? theme.black : theme.white)};
    }
  }
`

const RowDescription = ({ light, updateRow, open, text }) => {
  const [descriptionRef, { height }] = useMeasure({ polyfill: ResizeObserver })

  const springProps = useSpring({
    height: open ? height : 0,
    opacity: open ? 1 : 0,
  })
  return (
    <DescriptionWrapper light={light} style={{ ...springProps }}>
      <Description ref={descriptionRef}>
        <Close light={light} onClick={updateRow} title="Close">
          <svg
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="0.347213"
              y1="1.19295"
              x2="29.931"
              y2="29.7431"
              stroke="black"
            />
            <line
              x1="29.6812"
              y1="1.28178"
              x2="0.609611"
              y2="30.3534"
              stroke="black"
            />
          </svg>
        </Close>
        <p>{text}</p>
      </Description>
    </DescriptionWrapper>
  )
}

export default RowDescription
