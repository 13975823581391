import React, { useState } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px;

  @media only screen and (max-width: 390px) {
    padding: 15px;
  }

  @media only screen and (min-width: 1250px) {
    display: flex;
    padding-bottom: 25px;
  }
`

const Socials = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 25px;

  @media only screen and (min-width: 1250px) {
    margin-bottom: 0;
    padding-right: 80px;
  }
`

const SocialLink = styled.a`
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 5px;

  @media only screen and (max-width: 390px) {
    font-size: 20px;
    line-height: 24px;
  }

  @media only screen and (min-width: 1250px) {
    font-size: 31px;
    line-height: 45px;
  }

  @media only screen and (min-width: 1400px) {
    font-size: 37px;
    line-height: 41px;
  }
`

const Heading = styled.h2`
  font-size: 24px;
  line-height: 28px;

  @media only screen and (max-width: 390px) {
    font-size: 20px;
    line-height: 24px;
  }

  @media only screen and (min-width: 1250px) {
    font-size: 31px;
    line-height: 45px;
    margin-bottom: 5px;
  }

  @media only screen and (min-width: 1400px) {
    font-size: 37px;
    line-height: 41px;
  }
`

const Mail = styled.button`
  font-size: 24px;
  line-height: 28px;
  font-family: ${({ theme }) => theme.fontItalic};
  cursor: pointer;

  @media only screen and (max-width: 390px) {
    font-size: 20px;
    line-height: 24px;
  }

  @media only screen and (min-width: 1250px) {
    font-size: 31px;
    line-height: 45px;
  }

  @media only screen and (min-width: 1400px) {
    font-size: 37px;
    line-height: 41px;
  }
`

const Contact = props => {
  const mail = 'info@muzzle.website'
  const [mailText, setMailText] = useState(mail)

  const handleMailClick = async event => {
    if (!navigator.clipboard) {
      // Clipboard API not available:
      const copyToClipboard = textToCopy => {
        var textArea

        const isOS = () => {
          //can use a better detection logic here
          return navigator.userAgent.match(/ipad|iphone/i)
        }

        const createTextArea = text => {
          textArea = document.createElement('textArea')
          textArea.readOnly = true
          textArea.contentEditable = true
          textArea.value = text
          document.body.appendChild(textArea)
        }

        const selectText = () => {
          var range, selection

          if (isOS()) {
            range = document.createRange()
            range.selectNodeContents(textArea)
            selection = window.getSelection()
            selection.removeAllRanges()
            selection.addRange(range)
            textArea.setSelectionRange(0, 999999)
          } else {
            textArea.select()
          }
        }

        const copyTo = () => {
          document.execCommand('copy')
          document.body.removeChild(textArea)
        }

        createTextArea(textToCopy)
        selectText()
        copyTo()

        setMailText('Copied')
        setTimeout(() => {
          setMailText(mail)
        }, 2000)
      }

      copyToClipboard(event.target.innerText)

      return
    }
    const text = event.target.innerText
    try {
      await navigator.clipboard.writeText(text)
      setMailText('Copied')
      setTimeout(() => {
        setMailText(mail)
      }, 2000)
    } catch (err) {
      console.error('Failed to copy!', err)
    }
  }

  return (
    <Wrapper>
      <Socials>
        <SocialLink
          href="https://www.instagram.com/muzzlenoir/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Instagram
        </SocialLink>
        <SocialLink
          href="https://www.behance.net/muzzle_"
          target="_blank"
          rel="noopener noreferrer"
        >
          Behance
        </SocialLink>
      </Socials>
      <div>
        <Heading>Get in touch</Heading>
        <Mail onClick={e => handleMailClick(e)}>{mailText}</Mail>
      </div>
    </Wrapper>
  )
}

export default Contact
