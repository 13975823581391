import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'

import Nav from './Nav'

const Wrapper = styled.div`
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  transition: height 0.15s ease-in-out;
  padding: 50px 30px 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: 390px) {
    padding: 30px 15px 7px;
  }

  @media only screen and (min-width: 1250px) {
    padding: 60px 40px 30px;
  }
`

const Logo = styled.h1`
  width: 63px;
  height: 66px;
  position: relative;
  z-index: 1;
  mix-blend-mode: ${({ isPlaying, light }) =>
    isPlaying && !light ? 'difference' : 'none'};

  @media only screen and (orientation: landscape) and (max-width: 1023px) {
    width: 43px;
    height: 46px;
    margin-bottom: 30px;
  }

  @media only screen and (min-width: 1250px) {
    margin-bottom: 0;
  }

  @media only screen and (min-width: 1400px) {
    width: 95px;
    height: 100px;
  }

  svg {
    width: 100%;
    height: 100%;

    path {
      transition: fill ${({ theme }) => theme.inverseTrans};
      fill: ${({ theme, light }) => (light ? theme.black : theme.white)};
    }
  }
`

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  transition: opacity
    ${({ theme, light }) => `${light ? '0.15' : '1'}s ease-in-out`};
  opacity: ${({ light }) => (light ? 0 : 1)};
  display: ${({ show }) => (show ? 'block' : 'none')};
`

const Name = styled.span`
  display: none;
`

const TextWrapper = styled.div`
  @media only screen and (min-width: 1250px) {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`

const Description = styled.span`
  @media only screen and (min-width: 1250px) {
    width: 50%;
    padding: 50px 50px 50px 0;
  }
`

const Text = styled.div`
  font-size: 24px;
  line-height: 28px;
  max-width: 250px;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  mix-blend-mode: ${({ isPlaying, light }) =>
    isPlaying && !light ? 'difference' : 'none'};

  @media only screen and (max-width: 390px) {
    font-size: 20px;
    line-height: 24px;
  }

  @media only screen and (orientation: landscape) and (max-width: 1023px) {
    max-width: 100%;
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 24px;
  }

  @media only screen and (min-width: 1250px) {
    font-size: 40px;
    line-height: 44px;
    max-width: 100%;
    width: 100%;

    margin-bottom: ${({ noMargin }) => (noMargin ? 0 : '50px')};
  }

  @media only screen and (min-width: 1400px) {
    font-size: 56px;
    line-height: 60px;
  }
`

const Italic = styled.i`
  font-style: normal;
  font-family: ${({ theme }) => theme.fontItalic};
`

const Play = styled.button`
  font-size: 24px;
  line-height: 28px;
  position: relative;
  z-index: 1;
  mix-blend-mode: ${({ isPlaying, light }) =>
    isPlaying && !light ? 'difference' : 'none'};
  transition: opacity 0.15s ease-in-out;

  @media only screen and (max-width: 390px) {
    font-size: 20px;
    line-height: 24px;
  }

  @media only screen and (orientation: landscape) and (max-width: 1023px) {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 24px;
  }

  @media only screen and (min-width: 1250px) {
    font-size: 40px;
    line-height: 44px;
    margin: 0 auto;
  }

  @media only screen and (min-width: 1400px) {
    font-size: 56px;
    line-height: 60px;
  }
`

const Top = ({ light }) => {
  const videoMobRef = useRef(null)
  const videoDtRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  const [showMobileVideo, setShowMobileVideo] = useState(false)
  const [showDesktopVideo, setShowDesktopVideo] = useState(false)

  const playVideo = () => {
    setIsPlaying(true)

    videoDtRef.current.play()
    setShowMobileVideo(false)
    setShowDesktopVideo(true)
  }

  const toggleMute = () => {
    if (videoDtRef.current.muted) {
      videoDtRef.current.muted = false
      setIsMuted(false)
    } else {
      videoDtRef.current.muted = true
      setIsMuted(true)
    }
  }

  useEffect(() => {
    if (
      typeof window !== 'undefined'
    ) {
      setShowMobileVideo(false)
      setShowDesktopVideo(false)
      videoDtRef.current.setAttribute('preload', 'metadata')
    }
  }, [])

  return (
    <Wrapper>
      {/* <Video
        ref={videoMobRef}
        light={light}
        playsInline
        loop
        show={showMobileVideo}
        preload="none"
      >
        <source src={'https://player.vimeo.com/progressive_redirect/playback/844962296/rendition/1080p/file.mp4?loc=external&signature=fdc582e503e02cf662c6a4c57bacf88520fa6cf63912508633095c863d075935'} type="video/mp4" />
        Your browser does not support the video tag.
      </Video> */}

      <Video
        ref={videoDtRef}
        light={light}
        playsInline
        loop
        show={showDesktopVideo}
        preload="none"
      >
        {/* <source src={DesktopVideoWebm} type="video/webm" /> */}
        {/* <source src={DesktopVideo} type="video/mp4" /> */}
        <source src={'https://player.vimeo.com/progressive_redirect/playback/844962296/rendition/1080p/file.mp4?loc=external&signature=fdc582e503e02cf662c6a4c57bacf88520fa6cf63912508633095c863d075935'} type="video/mp4" />
        Your browser does not support the video tag.
      </Video>

      <Logo light={light} isPlaying={isPlaying}>
        <Name>Muzzle</Name>
        <svg
          width="4345.1"
          height="4601.5"
          version="1.1"
          viewBox="0 0 4345.1 4601.5"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g transform="matrix(1.3333 0 0 -1.3333 0 4601.5)">
            <g transform="scale(.1)">
              <g transform="scale(2.1172)">
                <path
                  d="m12230 16300c-1041 0-1949.7-529.9-2517.9-1363.1-567.72 833.2-1495.8 1363.1-2517.9 1363.1-1041 0-1949.7-529.9-2517.9-1363.1-568.19 833.2-1495.8 1363.1-2517.9 1363.1-833.16 0-1609.2-359.4-2158.5-927.6l870.95-871c340.53 322.1 794.9 530 1287.1 530 1060.3 0 1931.3-908.8 1931.3-2044.7v-1704.1l1192.6-1192.6v2897.2c0 1135.9 870.94 2044.6 1931.3 2044.6 1060.3 0 1930.8-909.2 1930.8-2044.6v-6758.8l1192.6-1192.6v7951.4c0 1135.9 870.9 2044.6 1931.3 2044.6 1060.3 0 1931.3-908.7 1931.3-2044.6v-11795l1192.5-1192.6v12987c-37.7 1835.9-1438.6 3312.8-3161.6 3312.8"
                  fill="#040606"
                />
              </g>
            </g>
          </g>
        </svg>
      </Logo>

      <TextWrapper>
        <Description>
          <Text isPlaying={isPlaying} light={light}>
            Muzzle is a creative unit specializing in{' '}
            <Italic>VFX, motion design, digital art</Italic> for moving image
            and communication.
          </Text>

          <Text isPlaying={isPlaying} light={light} noMargin>
            We experiment, break the rules and create the unreal
          </Text>
        </Description>

        <Play
          isPlaying={isPlaying}
          light={light}
          onClick={isPlaying ? toggleMute : playVideo}
        >
          ({isPlaying ? `Sound ${isMuted ? 'on' : 'off'}` : 'Click to play'})
        </Play>
      </TextWrapper>

      <Nav isPlaying={isPlaying} light={light} />
    </Wrapper>
  )
}

export default Top
