import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-scroll'

const Wrapper = styled.nav`
  display: flex;
  justify-content: space-between;
  mix-blend-mode: ${({ isPlaying, light }) =>
    isPlaying && !light ? 'difference' : 'none'};
  position: relative;
  z-index: 2;
`

const Button = styled.div`
  padding: 10px 0;
  text-decoration: none;${({ link }) => (link ? 'underline' : 'none')};
  cursor: pointer;

  @media only screen and (max-width: 390px) {
    font-size: 14px;
    line-height: 24px;
  }

  @media only screen and (min-width: 1250px) {
    font-size: 27px;
    line-height: 31px;
  }

  @media only screen and (min-width: 1400px) {
    font-size: 37px;
    line-height: 41px;
  }
`

const Nav = ({ isPlaying, light }) => {
  return (
    <Wrapper isPlaying={isPlaying} light={light}>
      <Button>
        <Link to="main" smooth={'easeOut'}>
          Hire us for
        </Link>
      </Button>
      <Button
        as="a"
        href="https://vimeo.com/844962296/93048d0b0c?share=copy"
        target="_blank"
        rel="noopener"
        link
      >
        Watch full reel
      </Button>
      <Button>
        <Link to="contact" smooth={'easeOut'}>
          Contact
        </Link>
      </Button>
    </Wrapper>
  )
}

export default Nav
